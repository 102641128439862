import React from "react"

import { KoreaJpLayout } from "@/components/layout"
import SEO from "../../components/seo"
import TabsComponent from "./tab"
import BannerComponent from "./banner"
import * as Styles from "./index.module.scss"
import { KoreaJpForm } from "../../components/form/KoreaJpForm"

/* ==================================================
  画像の読み込み
================================================== */
import arrow from "../../images/korea/message/arrow.svg"
import tamKoreaLogo from "../../images/korea/jumbotron/kantam_koreajp.png"
import tamKoreaLogoSp from "../../images/korea/jumbotron/kantam_koreajp_sp.png"

import messageImg01 from "../../images/korea/message/illust01_koreajp.png"
import messageImg01w from "../../images/korea/message/illust01_koreajp.webp"
import messageImg01Sp from "../../images/korea/message/illust01_koreajp_sp.png"
import messageImg01Spw from "../../images/korea/message/illust01_koreajp_sp.webp"

import marketGraph01 from "../../images/korea/market/graph01_koreajpkr.png"
import marketTable01 from "../../images/korea/market/table01_koreajpkr.png"
import marketTable01w from "../../images/korea/market/table01_koreajpkr.webp"
import marketGraph02 from "../../images/korea/market/graph02.png"

import companyHeading from "../../images/korea/company/company_kr_koreajp.svg"
import companyHeadingSp from "../../images/korea/company/company_kr_koreajp_sp.svg"
import companyImg from "../../images/korea/company/company_koreajp.svg"
import companyImgSp from "../../images/korea/company/company_koreajp_sp.svg"

import ServiceIcon01 from "../../images/korea/plan/icon_research_koreajp.svg"
import ServiceIcon02 from "../../images/korea/plan/icon_strategy_koreajp.svg"
import ServiceIcon03 from "../../images/korea/plan/icon_sns_koreajp.svg"
import ServiceIcon04 from "../../images/korea/plan/icon_create_koreajp.svg"
import ServiceIcon05 from "../../images/korea/plan/icon_influencer_koreajp.svg"
import ServiceIcon06 from "../../images/korea/plan/icon_advertise_koreajp.svg"

import resultCompany01 from "../../images/korea/result/company01.svg"
import resultCompany02 from "../../images/korea/result/company02.svg"
import resultCompany03 from "../../images/korea/result/company03.svg"
import resultCompany04 from "../../images/korea/result/company04.svg"
import resultCompany05 from "../../images/korea/result/company05.svg"
import resultCompany06 from "../../images/korea/result/company06.svg"
import resultCompany07 from "../../images/korea/result/company07.svg"
import resultCompany08 from "../../images/korea/result/company08.svg"
import resultCompany09 from "../../images/korea/result/company09.svg"
import resultCompany10 from "../../images/korea/result/company10.svg"
import resultCompany11 from "../../images/korea/result/company11.svg"
import resultCompany12 from "../../images/korea/result/company12.svg"
import resultCompany13 from "../../images/korea/result/company13.svg"
import resultCompany14 from "../../images/korea/result/company14.svg"
import resultCompany15 from "../../images/korea/result/company15.svg"
import resultCompany16 from "../../images/korea/result/company16.svg"
import resultCompany17 from "../../images/korea/result/company17.svg"
import resultCompany18 from "../../images/korea/result/company18.svg"
import resultCompany19 from "../../images/korea/result/company19.svg"
import resultCompany20 from "../../images/korea/result/company20.svg"
import resultCompany21 from "../../images/korea/result/company21.svg"

// companyロゴをオブジェクトに格納
const supportLogos = [
  { src: resultCompany01, alt: "KYOCERA Logo" },
  { src: resultCompany02, alt: "Panasonic Logo" },
  { src: resultCompany03, alt: "Nestle Logo" },
  { src: resultCompany04, alt: "FOSSIL Logo" },
  { src: resultCompany05, alt: "TOKYO METROPOLITAN GOVERNMENT Logo" },
  { src: resultCompany06, alt: "ANA Logo" },
  { src: resultCompany07, alt: "TATUNG Logo" },
  { src: resultCompany08, alt: "YANMAR Logo" },
]

const supportListItems = supportLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultSupportItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const ecLogos = [
  { src: resultCompany09, alt: "Belle Maison Logo" },
  { src: resultCompany10, alt: "Daimaru Matsuzakaya Department Stores Logo" },
  { src: resultCompany11, alt: "Kohnan Logo" },
  { src: resultCompany12, alt: "HIMARAYA Logo" },
  { src: resultCompany13, alt: "AOYAMA TAILOR Logo" },
  { src: resultCompany14, alt: "SUIT SQUARE Logo" },
  { src: resultCompany15, alt: "hare:kari AOYAMA Logo" },
  { src: resultCompany16, alt: "SHIMA SEIKI Logo" },
]

// ロゴとaltテキストを使用してリストアイテムを生成
const ecListItems = ecLogos.map((logo, index) => (
  <li key={index} className={Styles.topResultEcItem}>
    <img
      src={logo.src}
      alt={logo.alt}
      width="164"
      height="74"
      className={Styles.topResultSupportImage}
    />
  </li>
))

const KoreaTopPage: React.VFC = () => {
  return (
    <>
      <SEO
        title="한국 진출·크로스보더 이커머스는 주식회사 TAM｜일본 기업을 위한 한국 마케팅·E커머스 전략 지원"
        lang="ko"
        description="일본 기업의 한국 시장 진출을 일관되게 지원합니다. 시장 조사, 브랜드 구축, SNS 프로모션, 크리에이티브 제작, SEO 및 광고 운영까지, 한국 시장과 사용자의 이해를 바탕으로 한 전략으로 성공을 지향합니다."
        image="https://oh-tam.com/korea/ogp_koreajp_kr_2.jpg"
        url="https://oh-tam.com/koreajp_kr"
        canonical="https://oh-tam.com/koreajp_kr"
        keywords="일본 기업, 한국 진출, 마케팅, 이커머스, 시장 조사, 브랜드 구축, SNS 프로모션, 크리에이티브 제작, SEO, 광고 운영"
      />
      <KoreaJpLayout language="ko">
        {/* FV */}
        <section className={Styles.topJumbotron} id={Styles.topJumbotron}>
          <div className={Styles.topJumbotronInner}>
            <div className={Styles.topJumbotronHeadingWrapper}>
              <h1 className={Styles.topJumbotronHeading}>
                <picture>
                  <source media="(max-width: 767px)" srcSet={tamKoreaLogoSp} />
                  <img
                    src={tamKoreaLogo}
                    alt="Let's start marketing to Korea with TAMCHANG"
                    width="525"
                    height="106"
                  />
                </picture>
              </h1>
              <div className={Styles.topJumbotronTextWrapper}>
                <p className={Styles.topJumbotronSubTitle}>
                  한국 시장으로의 진출 지원
                </p>
                <p className={Styles.topJumbotronText}>
                  마케팅·국경을 넘나드는 E-커머스를
                  <br />
                  일관되게 지원
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Message */}
        <section className={Styles.topMessage} id={Styles.topMessage}>
          <div className={Styles.topMessageInner}>
            <div className={Styles.topMessageContainer}>
              <h2
                className={`${Styles.topMessageHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>메시지</span>
                <span className={Styles.en}>Message</span>
              </h2>
              <p className={Styles.topMessageSubtitle}>
                한국 시장으로의 진출을 목표로 한다면, <br />
                반드시 TAMCHANG에 상담해 주세요.
              </p>
              <p className={Styles.topMessageText}>
                한국 현지 및 일본과 한국 사이의 관계에 정통한 프로젝트 멤버가
                시장 조사 및 웹사이트·E-커머스 사이트 설립, 프로모션, 브랜드
                인지도를 전반적으로 지원합니다.
              </p>
              <p className={Styles.topMessageText}>
                한국의 소비자나 비즈니스 수요에 부합한 최적의 계획을
                제안·실시합니다. 당신의 비즈니스를 한국 시장에서 도약시켜
                봅시다!
              </p>

              <div className={Styles.topMessageLinkWrapper}>
                <a
                  href="https://www.tam-tam.co.jp/"
                  className={Styles.topMessageLink}
                  target="_blank"
                >
                  소속된 TAM 그룹에 대해서는 여기를 클릭하세요
                  <img
                    src={arrow}
                    alt="arrow"
                    width="11"
                    height="11"
                    className={Styles.topMessageLinkArrow}
                  />
                </a>
              </div>
            </div>
            <div className={Styles.topMessageImageWrapper}>
              <picture>
                <source media="(max-width: 767px)" srcSet={messageImg01Spw} />
                <source media="(max-width: 767px)" srcSet={messageImg01Sp} />
                <source srcSet={messageImg01w} />
                <img
                  src={messageImg01}
                  alt="TAM member"
                  width="691"
                  height="537"
                  className={Styles.topMessageImage}
                />
              </picture>
            </div>
          </div>
        </section>

        {/* Market */}
        <section className={Styles.topMarket} id={Styles.topMarket}>
          <div className={Styles.topMarketInner}>
            <div className={Styles.topMarketHeadingWrapper}>
              <h2
                className={`${Styles.topMarketHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>시장</span>
                <span className={Styles.en}>Market</span>
              </h2>
              <p className={Styles.topMarketSubtitle}>
                한국 비즈니스는 기회가 가득!
              </p>
            </div>
            <ul className={Styles.topMarketList}>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    01
                    <span>To Customer</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    한국은 일본에 대한 강한 관심이{" "}
                    <br className={Styles.spOnly} />
                    높습니다
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        일본여행객 중 한국인이 가장 많으며,{" "}
                        <br className={Styles.spOnly} />
                        2019년 대비 47% 증가
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <img
                          src={marketGraph01}
                          alt="Foreign Tourist Statistic"
                          width="335"
                          height="152"
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          출처: JNTO '2024년 2월 방일외국인/2024년 1-2월 누적'
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        일본에게 한국은 5위, 한국에게{" "}
                        <br className={Styles.pcOnly} />
                        일본은 <br className={Styles.spOnly} />
                        4위의 무역 상대국
                        <br />
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <picture>
                          <source
                            media="(max-width: 767px)"
                            srcSet={marketTable01w}
                          />
                          <img
                            src={marketTable01}
                            alt="탐그룹"
                            width="270"
                            height="109"
                            className={Styles.topMarketDataItemImg}
                          />
                        </picture>
                        <p className={Styles.topMarketDataItemRef}>
                          출처: 외무부 홈페이지에 게재된 '대한민국 기본 데이터'{" "}
                          <br />
                          2024년 3월 14일 기준
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li className={Styles.topMarketItem}>
                <div className={Styles.topMarketDataContainer}>
                  <h3 className={Styles.topMarketHeadingLv3}>
                    02
                    <span>To Business</span>
                  </h3>
                  <p className={Styles.topMarketItemSubtitle}>
                    일본 기업들은 한국 시장 진출에 적극적
                  </p>
                  <ul className={Styles.topMarketDataList}>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        해외진출 일본기업의 <br className={Styles.pcOnly} />
                        영업이익에서 <br className={Styles.spOnly} />
                        2년 연속 1위
                      </p>
                      <div className={Styles.topMarketDataItemImgWrapper}>
                        <img
                          src={marketGraph02}
                          alt="1st: South Korea 85.5% 2nd: Australia 81.8% 3rd: Taiwan 79.2"
                          width="287"
                          height="151"
                          className={Styles.topMarketDataItemImg}
                        />
                        <p className={Styles.topMarketDataItemRef}>
                          출처：JETRO '2022년도 해외진출 일본기업 현황조사 |
                          한국편'
                        </p>
                      </div>
                    </li>
                    <li className={Styles.topMarketDataItem}>
                      <p className={Styles.topMarketDataItemText}>
                        70% 이상의 닛케이 기업이{" "}
                        <br className={Styles.pcOnly} />
                        한국 판매 기능을 확대할 예정
                      </p>
                      <div className={Styles.topMarketDataItemNumWrapper}>
                        <div className={Styles.topMarketDataItemNum}>
                          74<span className={Styles.dot}>.</span>4
                          <span className={Styles.percent}>%</span>
                        </div>
                        <p className={Styles.topMarketDataItemRef}>
                          출처：JETRO '2022년도 해외진출 일본기업 현황조사 |
                          한국편'
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* Company */}
        <section className={Styles.topCompany} id="topCompany">
          <div className={Styles.topCompanyInner}>
            <h2
              className={`${Styles.topCompanyHeading} ${Styles.topHeadingLv2}`}
            >
              <picture>
                <source media="(max-width: 767px)" srcSet={companyHeadingSp} />
                <img
                  src={companyHeading}
                  alt="탐그룹"
                  width="464"
                  height="181"
                  className={Styles.topCompanyHeadingImage}
                />
              </picture>
              <span className={Styles.en}>
                <span className={Styles.isSmall}>Global</span>Company
              </span>
            </h2>
            <div className={Styles.topCompanyImageWrapper}>
              <picture className={Styles.topCompanyPic}>
                <source media="(max-width: 767px)" srcSet={companyImgSp} />
                <img
                  src={companyImg}
                  alt="A map showing the locations of the company's offices scattered across the world. Marks are placed in Tokyo and Osaka as central points, with additional offices in Amsterdam, London, Taiwan, Singapore, San Francisco, and Hawaii."
                  width="938"
                  height="494"
                  className={Styles.topCompanyImage}
                />
              </picture>
            </div>
          </div>
        </section>

        <section className={Styles.topPlan} id="topPlan">
          <TabsComponent />
          <div className={Styles.topService} id="topService">
            <div className={Styles.topServiceInner}>
              <h2
                className={`${Styles.topServiceHeading} ${Styles.topHeadingLv2}`}
              >
                <span className={Styles.ko}>서비스 메뉴</span>
                <span className={Styles.en}>
                  Service
                  <br className={Styles.spOnly} /> Menu
                </span>
              </h2>
              <p className={Styles.topServiceSubtitle}>
                진출 계획에 맞추어, 본사와 현지 및 일한 관계에 정통한 파트너와
                협력 체제를 구축하여 지원합니다.
              </p>
              <ul className={Styles.topServiceList}>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemResearch}`}
                >
                  <img
                    src={ServiceIcon01}
                    alt="research"
                    width="78"
                    height="78"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>한국 시장 조사</p>
                  <p className={Styles.topServiceItemText}>
                    한국 현지 리서치와 일한 정보에 정통한 멤버와 협력하여
                    리서치를 진행하며,{" "}
                    <span>한국 시장의 가능성이나 사용자의 행동, 취향</span> 등을
                    조사합니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemStrategy}`}
                >
                  <img
                    src={ServiceIcon02}
                    alt="strategy"
                    width="60"
                    height="73"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>브랜드 구축·전략</p>
                  <p className={Styles.topServiceItemText}>
                    한국 사용자의 관심사에 맞춘{" "}
                    <span>마케팅/크리에이티브 계획을 수립하고 실행합니다.</span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemSns}`}
                >
                  <img
                    src={ServiceIcon03}
                    alt="sns"
                    width="70"
                    height="79"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>SNS·프로모션</p>
                  <p className={Styles.topServiceItemText}>
                    Naver, kakao, Instagram, TikTok 등 타겟에 맞는 플랫폼과 소셜
                    미디어를 활용하여{" "}
                    <span>전략 수립부터 제작, 운영까지 지원합니다.</span>
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemCreate}`}
                >
                  <img
                    src={ServiceIcon04}
                    alt="create"
                    width="71"
                    height="71"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    크리에이티브 제작·사이트 구축
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>마케팅 전략에 기반한 제작을</span> 진행합니다.
                    기획부터 디자인·라이팅까지 일관된 작업이 가능한 것이
                    강점입니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemInfluencer}`}
                >
                  <img
                    src={ServiceIcon05}
                    alt="influencer"
                    width="81"
                    height="58"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>
                    SEO·광고 설계·운영
                  </p>
                  <p className={Styles.topServiceItemText}>
                    <span>
                      한국의 각 광고 플랫폼의 특성과 타겟 사용자의 행동에 맞추어
                      SEO 및 광고 콘텐츠의 제작·운영을
                    </span>{" "}
                    실시합니다.
                  </p>
                </li>
                <li
                  className={`${Styles.topServiceItem} ${Styles.topServiceItemAdvertise}`}
                >
                  <img
                    src={ServiceIcon06}
                    alt="advertise"
                    width="70"
                    height="60"
                    className={Styles.topServiceItemImage}
                  />
                  <p className={Styles.topServiceItemTitle}>현지 법인 설립</p>
                  <p className={Styles.topServiceItemText}>
                    귀사의 진출 계획에 맞추어,{" "}
                    <span>
                      일한 및 회사 설립에 상세한 사무소를 소개드립니다.
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className={Styles.topResult} id="topResult">
          <div className={Styles.topResultInner}>
            <div className={Styles.topResultBgWhite}>
              <div className={Styles.topResultBgWhiteContainer}>
                <h2
                  className={`${Styles.topResultHeading} ${Styles.topHeadingLv2}`}
                >
                  <span className={Styles.ko}>지원 실적</span>
                  <span className={Styles.en}>
                    <span className={Styles.isSmall}>Support</span>
                    <span>results</span>
                  </span>
                </h2>
                <p className={Styles.topResultSubTitle}>
                  또한, 고객님의 요청이나 사업 상황에 따라 플랜을
                  커스터마이즈하겠습니다!
                </p>
                <div className={Styles.topResultSupportContainer}>
                  <div className={Styles.topResultSupportTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      TAM지원실적
                    </h3>
                    <p className={Styles.topResultSupportText}>
                      TAM 그룹이 파트너로서 마케팅을 지원한 기업의 일부를
                      소개합니다.
                    </p>
                  </div>
                  <ul className={Styles.topResultSupportList}>
                    {supportListItems}
                  </ul>
                </div>
                <div className={Styles.topResultEcContainer}>
                  <div className={Styles.topResultEcTextWrapper}>
                    <h3
                      className={`${Styles.topResultHeadingLv4} ${Styles.topHeadingLv4}`}
                    >
                      E-커머스 지원 실적
                    </h3>
                    <p className={Styles.topResultEcText}>
                      지금까지 파트너로서 E-커머스에서 지원한 기업의 일부를
                      소개합니다.
                    </p>
                  </div>
                  <div className={Styles.topResultEcListContainer}>
                    <ul className={Styles.topResultEcList}>{ecListItems}</ul>
                    <ul className={Styles.topResultEcList}>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany17}
                          alt={"INE Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany18}
                          alt={"Heart Plus Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li className={Styles.topResultEcItem}>
                        <img
                          src={resultCompany19}
                          alt={"MEDICUS SHUPPAN,Publishers Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.spOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                    <ul className={Styles.topResultEcList}>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany20}
                          alt={"BRUNCH PARK Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                      <li
                        className={`${Styles.topResultEcItem} ${Styles.pcOnly}`}
                      >
                        <img
                          src={resultCompany21}
                          alt={"BABBI Logo"}
                          width="164"
                          height="74"
                          className={Styles.topResultSupportImage}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
                <p className={Styles.topResultCaution}>
                  ※주식회사 TAM 및 그룹사 전체의 지원 실적입니다.
                  <br className={Styles.spOnly} /> 해외 및 한국 진출 실적은
                  아닙니다.
                </p>
              </div>
            </div>
          </div>
        </section>
        <KoreaJpForm language="ko" />
        <BannerComponent />
      </KoreaJpLayout>
    </>
  )
}

export default KoreaTopPage
