import React, { useState, useCallback, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as Styles from "./index.module.scss"

import banner from "../../images/korea/banner/banner_koreajp_kr.jpg"
import bannerw from "../../images/korea/banner/banner_koreajp_kr.webp"
import bannerSp from "../../images/korea/banner/banner_koreajp_kr_sp.jpg"
import bannerSpw from "../../images/korea/banner/banner_koreajp_kr_sp.webp"

const BannerComponent = () => {
  return (
    <div className={Styles.topBanner}>
      <picture>
        <source media="(max-width: 767px)" srcSet={bannerSpw} />
        <source media="(max-width: 767px)" srcSet={bannerSp} />
        <source srcSet={bannerw} />
        <img
          src={banner}
          alt="협업 파트너 모집 중! 일본 기업의 한국 진출을 함께 지원해주실 한국 기업이나 프리랜서 파트너님을 모집하고 있습니다. 마음 편히 연락 주세요. 언제든지 이야기 나누는 시간을 갖고 싶습니다."
          width="688"
          height="216"
          className={Styles.topBannerImg}
        />
      </picture>
    </div>
  )
}

export default BannerComponent
